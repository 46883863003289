/** Convert account number */
export const interAccountMask = (value, evt) => {
  value = value.replace(/-/gi, '')
  if (value.length > 5) {
    return value.replace(/(\d{1}$)/, '-$1')
  } else return value
}

/**
 * Debounce
 */
export const debounce = (func, wait, immediate) => {
  let timeout
  return () => {
    const context = this
    const later = () => {
      timeout = null
      if (!immediate) func.apply(context)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context)
  }
}

/**
 * Throttle
 */
export const throttle = (func, limit) => {
  let lastFunc
  let lastRan
  return function () {
    const context = this
    const args = arguments
    if (!lastRan) {
      func.apply(context, args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(() => {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}

export function getHexColor () {
  const colors = [
    '#039BE5',
    '#C0CA33',
    '#A1887F',
    '#26A69A',
    '#7986CB',
    '#7CB342',
    '#4FC3F7',
    '#DCE775',
    '#BCAAA4',
    '#FFAB91',
    '#B39DDB',
    '#80CBC4',
  ]
  const randomIndex = Math.floor(Math.random() * colors.length)
  return colors[randomIndex]
}

export const objectHaveKeys = (object) => {
  if (object === 'undefined') {
    return false
  } else {
    return object && Object.keys(object).length !== 0
  }
}

export function getMonthAndYear (dateString) {
  const dateParts = dateString.split('/')
  const day = parseInt(dateParts[0], 10) // Convert day string to a number
  const month = parseInt(dateParts[1], 10) // Convert month string to a number
  const year = parseInt(dateParts[2], 10) // Convert year string to a number

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return dateString
  } else {
    const options = { month: 'short' }
    const formatter = new Intl.DateTimeFormat('en-US', options)

    const monthAbbreviation = formatter.format(new Date(2000 + year, month - 1, day))
    const yearAbbreviation = year % 100 // Get the last two digits of the year

    return `${monthAbbreviation} ${yearAbbreviation}`
  }
}

export function haveAlphabeticChar (str) {
  return /^\d{1,2}\/\d{1,2}\/\d{1,2}$/.test(str)
}

export function removeUndefinedNameFromChartArray (array) {
  return array.filter((obj) => obj.name !== undefined)
}

export function formatToDayMonthYearDate (array) {
  array.forEach((obj) => {
    if (haveAlphabeticChar(obj.name)) {
      const date = obj.name.split('/')
      obj.name = `${date[1]}/${date[0]}/${date[2]}`
    } else {
      return true
    }
  })
}

export function removeDuplicatedObjectFromChartArray (array) {
  return array.filter((object, index) => index === array.findIndex((obj) => obj.name === object.name))
}

export function returnChartKeysList (array) {
  const keysList = []
  const keys = Object.keys(array[0])

  keys.forEach((key) => {
    if (key !== 'name') {
      keysList.push({
        lineDataKey: key,
      })
    }
  })

  return keysList
}
